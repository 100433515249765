.contact-text {
  margin-left: 20%;
  margin-right: 20%;
  font-size: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .contact-text {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 767px) {
  .contact-text {
    margin-left: 5%;
    margin-right: 5%;
  }
}
