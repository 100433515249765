@font-face {
  font-family: "devicon";
  src: url("fonts/devicon.eot?biu5f3");
  src: url("fonts/devicon.eot?biu5f3#iefix") format("embedded-opentype"),
    url("fonts/devicon.ttf?biu5f3") format("truetype"),
    url("fonts/devicon.woff?biu5f3") format("woff"),
    url("fonts/devicon.svg?biu5f3#devicon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="devicon-"],
[class*=" devicon-"] {
  font-family: "devicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.devicon-openal-plain:before {
  content: "";
}
.devicon-kaggle-original-wordmark:before,
.devicon-kaggle-plain-wordmark:before {
  content: "";
}
.devicon-kaggle-original:before,
.devicon-kaggle-plain:before {
  content: "";
}
.devicon-pytest-plain-wordmark:before {
  content: "";
}
.devicon-pytest-plain:before {
  content: "";
}
.devicon-fastapi-plain-wordmark:before {
  content: "";
}
.devicon-fastapi-plain:before {
  content: "";
}
.devicon-opencv-plain-wordmark:before {
  content: "";
}
.devicon-opencv-plain:before {
  content: "";
}
.devicon-k3s-plain-wordmark:before {
  content: "";
}
.devicon-k3s-original:before,
.devicon-k3s-plain:before {
  content: "";
}
.devicon-packer-original-wordmark:before,
.devicon-packer-plain-wordmark:before {
  content: "";
}
.devicon-packer-original:before,
.devicon-packer-plain:before {
  content: "";
}
.devicon-anaconda-original-wordmark:before,
.devicon-anaconda-plain-wordmark:before {
  content: "";
}
.devicon-anaconda-original:before,
.devicon-anaconda-plain:before {
  content: "";
}
.devicon-prometheus-original-wordmark:before {
  content: "";
}
.devicon-prometheus-original:before {
  content: "";
}
.devicon-rspec-original-wordmark:before,
.devicon-rspec-plain-wordmark:before {
  content: "";
}
.devicon-rspec-original:before,
.devicon-rspec-plain:before {
  content: "";
}
.devicon-argocd-plain-wordmark:before {
  content: "";
}
.devicon-argocd-plain:before {
  content: "";
}
.devicon-blender-original-wordmark:before {
  content: "";
}
.devicon-blender-original:before {
  content: "";
}
.devicon-jetbrains-plain:before {
  content: "";
}
.devicon-cmake-plain-wordmark:before {
  content: "";
}
.devicon-cmake-plain:before {
  content: "";
}
.devicon-dropwizard-plain:before {
  content: "";
}
.devicon-nuget-original-wordmark:before {
  content: "";
}
.devicon-nuget-original:before {
  content: "";
}
.devicon-podman-plain-wordmark:before {
  content: "";
}
.devicon-podman-plain:before {
  content: "";
}
.devicon-networkx-original:before {
  content: "";
}
.devicon-sqlite-plain-wordmark:before {
  content: "";
}
.devicon-sqlite-plain:before {
  content: "";
}
.devicon-discordjs-plain-wordmark:before {
  content: "";
}
.devicon-discordjs-plain:before {
  content: "";
}
.devicon-unrealengine-original-wordmark:before,
.devicon-unrealengine-plain-wordmark:before {
  content: "";
}
.devicon-unrealengine-original:before,
.devicon-unrealengine-plain:before {
  content: "";
}
.devicon-django-plain-wordmark:before {
  content: "";
}
.devicon-django-plain:before {
  content: "";
}
.devicon-devicon-line-wordmark:before {
  content: "";
}
.devicon-devicon-line:before {
  content: "";
}
.devicon-devicon-plain-wordmark:before {
  content: "";
}
.devicon-devicon-plain:before {
  content: "";
}
.devicon-bootstrap-plain-wordmark:before {
  content: "";
}
.devicon-bootstrap-plain:before {
  content: "";
}
.devicon-solidity-plain:before {
  content: "";
}
.devicon-polygon-plain:before {
  content: "";
}
.devicon-maya-plain-wordmark:before {
  content: "";
}
.devicon-maya-plain:before {
  content: "";
}
.devicon-xcode-plain:before {
  content: "";
}
.devicon-selenium-original:before,
.devicon-selenium-plain:before {
  content: "";
}
.devicon-ifttt-original:before,
.devicon-ifttt-plain:before {
  content: "";
}
.devicon-pytorch-plain-wordmark:before {
  content: "";
}
.devicon-pytorch-original:before,
.devicon-pytorch-plain:before {
  content: "";
}
.devicon-xamarin-original-wordmark:before,
.devicon-xamarin-plain-wordmark:before {
  content: "";
}
.devicon-xamarin-original:before,
.devicon-xamarin-plain:before {
  content: "";
}
.devicon-hugo-plain-wordmark:before {
  content: "";
}
.devicon-hugo-plain:before {
  content: "";
}
.devicon-grafana-original-wordmark:before,
.devicon-grafana-plain-wordmark:before {
  content: "";
}
.devicon-grafana-original:before,
.devicon-grafana-plain:before {
  content: "";
}
.devicon-ansible-plain-wordmark:before {
  content: "";
}
.devicon-ansible-plain:before {
  content: "";
}
.devicon-shotgrid-original-wordmark:before,
.devicon-shotgrid-plain-wordmark:before {
  content: "";
}
.devicon-shotgrid-plain:before {
  content: "";
}
.devicon-androidstudio-plain-wordmark:before {
  content: "";
}
.devicon-androidstudio-plain:before {
  content: "";
}
.devicon-eslint-original-wordmark:before,
.devicon-eslint-plain-wordmark:before {
  content: "";
}
.devicon-eslint-original:before,
.devicon-eslint-plain:before {
  content: "";
}
.devicon-latex-original:before,
.devicon-latex-plain:before {
  content: "";
}
.devicon-vuetify-plain:before {
  content: "";
}
.devicon-vuetify-line:before {
  content: "";
}
.devicon-sdl-plain:before {
  content: "";
}
.devicon-bitbucket-original-wordmark:before,
.devicon-bitbucket-plain-wordmark:before {
  content: "";
}
.devicon-bitbucket-original:before,
.devicon-bitbucket-plain:before {
  content: "";
}
.devicon-confluence-original-wordmark:before,
.devicon-confluence-plain-wordmark:before {
  content: "";
}
.devicon-confluence-original:before,
.devicon-confluence-plain:before {
  content: "";
}
.devicon-fedora-plain:before {
  content: "";
}
.devicon-rect-plain:before {
  content: "";
}
.devicon-bamboo-original-wordmark:before,
.devicon-bamboo-plain-wordmark:before {
  content: "";
}
.devicon-bamboo-original:before,
.devicon-bamboo-plain:before {
  content: "";
}
.devicon-jira-plain-wordmark:before {
  content: "";
}
.devicon-jira-plain:before {
  content: "";
}
.devicon-terraform-plain-wordmark:before {
  content: "";
}
.devicon-terraform-plain:before {
  content: "";
}
.devicon-webflow-original:before {
  content: "";
}
.devicon-gentoo-plain:before {
  content: "";
}
.devicon-gentoo-plain-wordmark:before {
  content: "";
}
.devicon-opensuse-plain-wordmark:before {
  content: "";
}
.devicon-opensuse-plain:before {
  content: "";
}
.devicon-storybook-plain-wordmark:before {
  content: "";
}
.devicon-storybook-plain:before {
  content: "";
}
.devicon-pandas-original-wordmark:before,
.devicon-pandas-plain-wordmark:before {
  content: "";
}
.devicon-pandas-original:before,
.devicon-pandas-plain:before {
  content: "";
}
.devicon-centos-plain-wordmark:before {
  content: "";
}
.devicon-centos-plain:before {
  content: "";
}
.devicon-azure-plain-wordmark:before {
  content: "";
}
.devicon-azure-plain:before {
  content: "";
}
.devicon-go-line:before {
  content: "";
}
.devicon-go-plain:before {
  content: "";
}
.devicon-go-original-wordmark:before,
.devicon-go-plain-wordmark:before {
  content: "";
}
.devicon-msdos-line:before {
  content: "";
}
.devicon-arduino-plain-wordmark:before {
  content: "";
}
.devicon-arduino-plain:before {
  content: "";
}
.devicon-qt-original:before,
.devicon-qt-plain:before {
  content: "";
}
.devicon-gcc-plain:before {
  content: "";
}
.devicon-svelte-plain-wordmark:before {
  content: "";
}
.devicon-svelte-plain:before {
  content: "";
}
.devicon-slack-plain-wordmark:before {
  content: "";
}
.devicon-slack-plain:before {
  content: "";
}
.devicon-canva-original:before,
.devicon-canva-plain:before {
  content: "";
}
.devicon-jamstack-plain-wordmark:before {
  content: "";
}
.devicon-jamstack-original:before,
.devicon-jamstack-plain:before {
  content: "";
}
.devicon-denojs-original-wordmark:before,
.devicon-denojs-plain-wordmark:before {
  content: "";
}
.devicon-denojs-original:before,
.devicon-denojs-plain:before {
  content: "";
}
.devicon-apachekafka-original-wordmark:before,
.devicon-apachekafka-plain-wordmark:before {
  content: "";
}
.devicon-apachekafka-original:before,
.devicon-apachekafka-plain:before {
  content: "";
}
.devicon-filezilla-plain-wordmark:before {
  content: "";
}
.devicon-filezilla-plain:before {
  content: "";
}
.devicon-opengl-plain:before {
  content: "";
}
.devicon-putty-plain:before {
  content: "";
}
.devicon-opera-plain-wordmark:before {
  content: "";
}
.devicon-opera-plain:before {
  content: "";
}
.devicon-subversion-original:before,
.devicon-subversion-plain:before {
  content: "";
}
.devicon-tortoisegit-line:before {
  content: "";
}
.devicon-tortoisegit-plain:before {
  content: "";
}
.devicon-towergit-plain-wordmark:before {
  content: "";
}
.devicon-towergit-plain:before {
  content: "";
}
.devicon-unity-original-wordmark:before {
  content: "";
}
.devicon-unity-original:before {
  content: "";
}
.devicon-neo4j-plain-wordmark:before {
  content: "";
}
.devicon-neo4j-plain:before {
  content: "";
}
.devicon-nuxtjs-plain-wordmark:before {
  content: "";
}
.devicon-nuxtjs-plain:before {
  content: "";
}
.devicon-socketio-original-wordmark:before,
.devicon-socketio-plain-wordmark:before {
  content: "";
}
.devicon-socketio-original:before,
.devicon-socketio-plain:before {
  content: "";
}
.devicon-processing-plain-wordmark:before {
  content: "";
}
.devicon-processing-plain:before {
  content: "";
}
.devicon-feathersjs-original:before {
  content: "";
}
.devicon-adonisjs-original-wordmark:before,
.devicon-adonisjs-plain-wordmark:before {
  content: "";
}
.devicon-adonisjs-original:before,
.devicon-adonisjs-plain:before {
  content: "";
}
.devicon-numpy-original-wordmark:before {
  content: "";
}
.devicon-numpy-original:before,
.devicon-numpy-plain:before {
  content: "";
}
.devicon-matlab-line:before {
  content: "";
}
.devicon-matlab-plain:before {
  content: "";
}
.devicon-clojurescript-plain:before {
  content: "";
}
.devicon-threejs-original-wordmark:before {
  content: "";
}
.devicon-threejs-original:before {
  content: "";
}
.devicon-markdown-original:before,
.devicon-markdown-plain:before {
  content: "";
}
.devicon-nixos-plain-wordmark:before {
  content: "";
}
.devicon-nixos-plain:before {
  content: "";
}
.devicon-perl-plain:before {
  content: "";
}
.devicon-tensorflow-line-wordmark:before {
  content: "";
}
.devicon-tensorflow-line:before {
  content: "";
}
.devicon-tensorflow-original-wordmark:before {
  content: "";
}
.devicon-tensorflow-original:before {
  content: "";
}
.devicon-zig-plain-wordmark:before {
  content: "";
}
.devicon-zig-original:before,
.devicon-zig-plain:before {
  content: "";
}
.devicon-raspberrypi-line-wordmark:before {
  content: "";
}
.devicon-raspberrypi-line:before {
  content: "";
}
.devicon-embeddedc-plain-wordmark:before {
  content: "";
}
.devicon-embeddedc-plain:before {
  content: "";
}
.devicon-labview-plain-wordmark:before {
  content: "";
}
.devicon-labview-plain:before {
  content: "";
}
.devicon-modx-plain-wordmark:before {
  content: "";
}
.devicon-modx-plain:before {
  content: "";
}
.devicon-knockout-plain-wordmark:before {
  content: "";
}
.devicon-rstudio-plain:before {
  content: "";
}
.devicon-phalcon-plain:before {
  content: "";
}
.devicon-minitab-plain:before {
  content: "";
}
.devicon-bulma-plain:before {
  content: "";
}
.devicon-spss-plain:before {
  content: "";
}
.devicon-karma-plain:before {
  content: "";
}
.devicon-jest-plain:before {
  content: "";
}
.devicon-circleci-plain-wordmark:before {
  content: "";
}
.devicon-circleci-plain:before {
  content: "";
}
.devicon-codecov-plain:before {
  content: "";
}
.devicon-magento-line:before {
  content: "";
}
.devicon-magento-original-wordmark:before {
  content: "";
}
.devicon-magento-original:before {
  content: "";
}
.devicon-shopware-original-wordmark:before {
  content: "";
}
.devicon-shopware-original:before {
  content: "";
}
.devicon-salesforce-plain:before {
  content: "";
}
.devicon-vuestorefront-plain:before {
  content: "";
}
.devicon-unix-original:before {
  content: "";
}
.devicon-godot-plain-wordmark:before {
  content: "";
}
.devicon-godot-plain:before {
  content: "";
}
.devicon-vscode-plain-wordmark:before {
  content: "";
}
.devicon-vscode-plain:before {
  content: "";
}
.devicon-julia-plain-wordmark:before {
  content: "";
}
.devicon-julia-plain:before {
  content: "";
}
.devicon-crystal-original-wordmark:before {
  content: "";
}
.devicon-crystal-original:before {
  content: "";
}
.devicon-tailwindcss-plain:before {
  content: "";
}
.devicon-tailwindcss-original-wordmark:before {
  content: "";
}
.devicon-weblate-plain-wordmark:before {
  content: "";
}
.devicon-weblate-plain:before {
  content: "";
}
.devicon-thealgorithms-plain-wordmark:before {
  content: "";
}
.devicon-thealgorithms-plain:before {
  content: "";
}
.devicon-spring-plain-wordmark:before {
  content: "";
}
.devicon-spring-plain:before {
  content: "";
}
.devicon-rails-plain-wordmark:before {
  content: "";
}
.devicon-rails-plain:before {
  content: "";
}
.devicon-phoenix-plain-wordmark:before {
  content: "";
}
.devicon-phoenix-plain:before {
  content: "";
}
.devicon-nextjs-original-wordmark:before,
.devicon-nextjs-plain-wordmark:before {
  content: "";
}
.devicon-nextjs-line:before {
  content: "";
}
.devicon-nextjs-original:before,
.devicon-nextjs-plain:before {
  content: "";
}
.devicon-lua-plain-wordmark:before {
  content: "";
}
.devicon-lua-plain:before {
  content: "";
}
.devicon-graphql-plain-wordmark:before {
  content: "";
}
.devicon-graphql-plain:before {
  content: "";
}
.devicon-gitter-plain-wordmark:before {
  content: "";
}
.devicon-gitter-plain:before {
  content: "";
}
.devicon-figma-plain:before {
  content: "";
}
.devicon-digitalocean-plain-wordmark:before {
  content: "";
}
.devicon-digitalocean-plain:before {
  content: "";
}
.devicon-dotnetcore-plain:before {
  content: "";
}
.devicon-dart-plain-wordmark:before {
  content: "";
}
.devicon-dart-plain:before {
  content: "";
}
.devicon-r-plain:before {
  content: "";
}
.devicon-r-original:before {
  content: "";
}
.devicon-ocaml-plain-wordmark:before {
  content: "";
}
.devicon-ocaml-plain:before {
  content: "";
}
.devicon-jupyter-plain-wordmark:before {
  content: "";
}
.devicon-jupyter-plain:before {
  content: "";
}
.devicon-fsharp-plain:before {
  content: "";
}
.devicon-elixir-plain-wordmark:before {
  content: "";
}
.devicon-elixir-plain:before {
  content: "";
}
.devicon-aarch64-plain:before {
  content: "";
}
.devicon-xd-line:before {
  content: "";
}
.devicon-xd-plain:before {
  content: "";
}
.devicon-uwsgi-plain:before {
  content: "";
}
.devicon-microsoftsqlserver-plain-wordmark:before {
  content: "";
}
.devicon-microsoftsqlserver-plain:before {
  content: "";
}
.devicon-sqlalchemy-original-wordmark:before,
.devicon-sqlalchemy-plain-wordmark:before {
  content: "";
}
.devicon-sqlalchemy-plain:before {
  content: "";
}
.devicon-rocksdb-plain:before {
  content: "";
}
.devicon-objectivec-plain:before {
  content: "";
}
.devicon-kubernetes-plain-wordmark:before {
  content: "";
}
.devicon-kubernetes-plain:before {
  content: "";
}
.devicon-googlecloud-plain-wordmark:before {
  content: "";
}
.devicon-googlecloud-plain:before {
  content: "";
}
.devicon-flask-original-wordmark:before,
.devicon-flask-plain-wordmark:before {
  content: "";
}
.devicon-flask-original:before,
.devicon-flask-plain:before {
  content: "";
}
.devicon-firebase-plain-wordmark:before {
  content: "";
}
.devicon-firebase-plain:before {
  content: "";
}
.devicon-eleventy-plain:before {
  content: "";
}
.devicon-appwrite-plain-wordmark:before,
.devicon-appwrite-original-wordmark:before {
  content: "";
}
.devicon-appwrite-plain:before,
.devicon-appwrite-original:before {
  content: "";
}
.devicon-bash-plain:before {
  content: "";
}
.devicon-woocommerce-plain-wordmark:before {
  content: "";
}
.devicon-woocommerce-plain:before {
  content: "";
}
.devicon-typo3-plain-wordmark:before {
  content: "";
}
.devicon-typo3-plain:before {
  content: "";
}
.devicon-premierepro-plain:before {
  content: "";
}
.devicon-nestjs-plain-wordmark:before {
  content: "";
}
.devicon-nestjs-plain:before {
  content: "";
}
.devicon-materialui-plain:before {
  content: "";
}
.devicon-kotlin-plain-wordmark:before {
  content: "";
}
.devicon-kotlin-plain:before {
  content: "";
}
.devicon-jenkins-plain:before {
  content: "";
}
.devicon-jenkins-line:before {
  content: "";
}
.devicon-haxe-plain:before {
  content: "";
}
.devicon-gatsby-plain-wordmark:before {
  content: "";
}
.devicon-gatsby-plain:before {
  content: "";
}
.devicon-flutter-plain:before {
  content: "";
}
.devicon-composer-line-wordmark:before,
.devicon-composer-plain-wordmark:before {
  content: "";
}
.devicon-composer-line:before,
.devicon-composer-plain:before {
  content: "";
}
.devicon-aftereffects-plain:before {
  content: "";
}
.devicon-yunohost-plain:before {
  content: "";
}
.devicon-redux-original:before,
.devicon-redux-plain:before {
  content: "";
}
.devicon-clojure-line:before,
.devicon-clojure-plain:before {
  content: "";
}
.devicon-mocha-plain:before {
  content: "";
}
.devicon-haskell-plain:before {
  content: "";
}
.devicon-haskell-plain-wordmark:before {
  content: "";
}
.devicon-codepen-original-wordmark:before {
  content: "";
}
.devicon-codepen-plain:before,
.devicon-codepen-original:before {
  content: "";
}
.devicon-groovy-plain:before {
  content: "";
}
.devicon-rust-plain:before {
  content: "";
}
.devicon-scala-plain-wordmark:before {
  content: "";
}
.devicon-scala-plain:before {
  content: "";
}
.devicon-grails-plain:before {
  content: "";
}
.devicon-sketch-line-wordmark:before {
  content: "";
}
.devicon-sketch-line:before {
  content: "";
}
.devicon-npm-original-wordmark:before {
  content: "";
}
.devicon-ionic-original-wordmark:before {
  content: "";
}
.devicon-ionic-original:before {
  content: "";
}
.devicon-ember-original-wordmark:before,
.devicon-ember-plain-wordmark:before {
  content: "";
}
.devicon-electron-original-wordmark:before {
  content: "";
}
.devicon-electron-original:before {
  content: "";
}
.devicon-vagrant-plain-wordmark:before {
  content: "";
}
.devicon-vagrant-plain:before {
  content: "";
}
.devicon-yarn-plain-wordmark:before {
  content: "";
}
.devicon-yarn-plain:before {
  content: "";
}
.devicon-handlebars-plain-wordmark:before {
  content: "";
}
.devicon-handlebars-plain:before {
  content: "";
}
.devicon-couchdb-plain-wordmark:before {
  content: "";
}
.devicon-couchdb-plain:before {
  content: "";
}
.devicon-behance-plain-wordmark:before {
  content: "";
}
.devicon-behance-plain:before {
  content: "";
}
.devicon-linkedin-plain-wordmark:before {
  content: "";
}
.devicon-linkedin-plain:before {
  content: "";
}
.devicon-ceylon-plain:before {
  content: "";
}
.devicon-elm-plain-wordmark:before {
  content: "";
}
.devicon-elm-plain:before {
  content: "";
}
.devicon-cakephp-plain-wordmark:before {
  content: "";
}
.devicon-cakephp-plain:before {
  content: "";
}
.devicon-stylus-original:before {
  content: "";
}
.devicon-express-original-wordmark:before {
  content: "";
}
.devicon-express-original:before {
  content: "";
}
.devicon-intellij-plain-wordmark:before {
  content: "";
}
.devicon-intellij-plain:before {
  content: "";
}
.devicon-pycharm-plain-wordmark:before {
  content: "";
}
.devicon-pycharm-plain:before {
  content: "";
}
.devicon-rubymine-plain-wordmark:before {
  content: "";
}
.devicon-rubymine-plain:before {
  content: "";
}
.devicon-webstorm-plain-wordmark:before {
  content: "";
}
.devicon-webstorm-plain:before {
  content: "";
}
.devicon-tomcat-line-wordmark:before {
  content: "";
}
.devicon-tomcat-line:before {
  content: "";
}
.devicon-vuejs-line-wordmark:before {
  content: "";
}
.devicon-vuejs-line:before {
  content: "";
}
.devicon-vuejs-plain-wordmark:before {
  content: "";
}
.devicon-vuejs-plain:before {
  content: "";
}
.devicon-swift-plain-wordmark:before {
  content: "";
}
.devicon-swift-plain:before {
  content: "";
}
.devicon-webpack-plain-wordmark:before {
  content: "";
}
.devicon-webpack-plain:before {
  content: "";
}
.devicon-visualstudio-plain-wordmark:before {
  content: "";
}
.devicon-visualstudio-plain:before {
  content: "";
}
.devicon-sequelize-plain-wordmark:before {
  content: "";
}
.devicon-sequelize-plain:before {
  content: "";
}
.devicon-typescript-plain:before,
.devicon-typescript-original:before {
  content: "";
}
.devicon-babel-plain:before,
.devicon-babel-original:before {
  content: "";
}
.devicon-facebook-plain:before,
.devicon-facebook-original:before {
  content: "";
}
.devicon-google-plain-wordmark:before,
.devicon-google-original-wordmark:before {
  content: "";
}
.devicon-google-plain:before,
.devicon-google-original:before {
  content: "";
}
.devicon-twitter-original:before,
.devicon-twitter-plain:before {
  content: "";
}
.devicon-mocha:before {
  content: "";
}
.devicon-jasmine-plain:before {
  content: "";
}
.devicon-jasmine-wordmark:before {
  content: "";
}
.devicon-gatling-plain:before {
  content: "";
}
.devicon-gatling-plain-wordmark:before {
  content: "";
}
.devicon-phpstorm-plain:before {
  content: "";
}
.devicon-phpstorm-plain-wordmark:before {
  content: "";
}
.devicon-sourcetree-original:before,
.devicon-sourcetree-plain:before {
  content: "";
}
.devicon-sourcetree-original-wordmark:before,
.devicon-sourcetree-plain-wordmark:before {
  content: "";
}
.devicon-ssh-original:before,
.devicon-ssh-plain:before {
  content: "";
}
.devicon-ssh-original-wordmark:before,
.devicon-ssh-plain-wordmark:before {
  content: "";
}
.devicon-jeet-plain:before {
  content: "";
}
.devicon-jeet-plain-wordmark:before {
  content: "";
}
.devicon-gitlab-plain:before {
  content: "";
}
.devicon-gitlab-plain-wordmark:before {
  content: "";
}
.devicon-github-original:before,
.devicon-github-plain:before {
  content: "";
}
.devicon-github-original-wordmark:before {
  content: "";
}
.devicon-d3js-plain:before {
  content: "";
}
.devicon-d3js-original:before {
  content: "";
}
.devicon-gradle-plain:before {
  content: "";
}
.devicon-gradle-plain-wordmark:before {
  content: "";
}
.devicon-cucumber-plain:before {
  content: "";
}
.devicon-cucumber-plain-wordmark:before {
  content: "";
}
.devicon-protractor-plain:before {
  content: "";
}
.devicon-protractor-plain-wordmark:before {
  content: "";
}
.devicon-safari-line-wordmark:before {
  content: "";
}
.devicon-safari-line:before {
  content: "";
}
.devicon-safari-plain-wordmark:before {
  content: "";
}
.devicon-safari-plain:before {
  content: "";
}
.devicon-gimp-plain:before {
  content: "";
}
.devicon-redhat-plain-wordmark:before {
  content: "";
}
.devicon-redhat-plain:before {
  content: "";
}
.devicon-cplusplus-line:before,
.devicon-cplusplus-line-wordmark:before {
  content: "";
}
.devicon-cplusplus-plain:before,
.devicon-cplusplus-plain-wordmark:before {
  content: "";
}
.devicon-csharp-line:before,
.devicon-csharp-line-wordmark:before {
  content: "";
}
.devicon-csharp-plain:before,
.devicon-csharp-plain-wordmark:before {
  content: "";
}
.devicon-c-line:before,
.devicon-c-line-wordmark:before {
  content: "";
}
.devicon-c-plain:before,
.devicon-c-plain-wordmark:before {
  content: "";
}
.devicon-nodewebkit-line-wordmark:before {
  content: "";
}
.devicon-nodewebkit-line:before {
  content: "";
}
.devicon-nodewebkit-plain-wordmark:before {
  content: "";
}
.devicon-nodewebkit-plain:before {
  content: "";
}
.devicon-nginx-original:before,
.devicon-nginx-original-wordmark:before,
.devicon-nginx-plain:before,
.devicon-nginx-plain-wordmark:before {
  content: "";
}
.devicon-erlang-plain-wordmark:before {
  content: "";
}
.devicon-erlang-plain:before {
  content: "";
}
.devicon-doctrine-line-wordmark:before {
  content: "";
}
.devicon-doctrine-line:before {
  content: "";
}
.devicon-doctrine-plain-wordmark:before {
  content: "";
}
.devicon-doctrine-plain:before {
  content: "";
}
.devicon-apache-line-wordmark:before {
  content: "";
}
.devicon-apache-line:before {
  content: "";
}
.devicon-apache-plain-wordmark:before {
  content: "";
}
.devicon-apache-plain:before {
  content: "";
}
.devicon-redis-plain-wordmark:before {
  content: "";
}
.devicon-redis-plain:before {
  content: "";
}
.devicon-meteor-plain-wordmark:before {
  content: "";
}
.devicon-meteor-plain:before {
  content: "";
}
.devicon-heroku-line-wordmark:before,
.devicon-heroku-original-wordmark:before {
  content: "";
}
.devicon-heroku-line:before,
.devicon-heroku-original:before {
  content: "";
}
.devicon-heroku-plain-wordmark:before {
  content: "";
}
.devicon-heroku-plain:before {
  content: "";
}
.devicon-docker-plain-wordmark:before {
  content: "";
}
.devicon-docker-plain:before {
  content: "";
}
.devicon-symfony-original-wordmark:before,
.devicon-symfony-plain-wordmark:before {
  content: "";
}
.devicon-symfony-original:before,
.devicon-symfony-plain:before {
  content: "";
}
.devicon-react-original-wordmark:before,
.devicon-react-plain-wordmark:before {
  content: "";
}
.devicon-react-original:before,
.devicon-react-plain:before {
  content: "";
}
.devicon-amazonwebservices-original:before,
.devicon-amazonwebservices-plain:before {
  content: "";
}
.devicon-amazonwebservices-plain-wordmark:before {
  content: "";
}
.devicon-android-plain-wordmark:before {
  content: "";
}
.devicon-android-plain:before {
  content: "";
}
.devicon-angularjs-plain-wordmark:before {
  content: "";
}
.devicon-angularjs-plain:before {
  content: "";
}
.devicon-appcelerator-original:before,
.devicon-appcelerator-plain:before {
  content: "";
}
.devicon-appcelerator-plain-wordmark:before {
  content: "";
}
.devicon-apple-original:before,
.devicon-apple-plain:before {
  content: "";
}
.devicon-atom-original-wordmark:before,
.devicon-atom-plain-wordmark:before {
  content: "";
}
.devicon-atom-original:before,
.devicon-atom-plain:before {
  content: "";
}
.devicon-backbonejs-plain-wordmark:before {
  content: "";
}
.devicon-backbonejs-plain:before {
  content: "";
}
.devicon-bower-line-wordmark:before {
  content: "";
}
.devicon-bower-line:before {
  content: "";
}
.devicon-bower-plain-wordmark:before {
  content: "";
}
.devicon-bower-plain:before {
  content: "";
}
.devicon-chrome-plain-wordmark:before {
  content: "";
}
.devicon-chrome-plain:before {
  content: "";
}
.devicon-codeigniter-plain-wordmark:before {
  content: "";
}
.devicon-codeigniter-plain:before {
  content: "";
}
.devicon-coffeescript-original-wordmark:before,
.devicon-coffeescript-plain-wordmark:before {
  content: "";
}
.devicon-coffeescript-original:before,
.devicon-coffeescript-plain:before {
  content: "";
}
.devicon-css3-plain-wordmark:before {
  content: "";
}
.devicon-css3-plain:before {
  content: "";
}
.devicon-debian-plain-wordmark:before {
  content: "";
}
.devicon-debian-plain:before {
  content: "";
}
.devicon-dot-net-plain-wordmark:before {
  content: "";
}
.devicon-dot-net-plain:before {
  content: "";
}
.devicon-drupal-plain-wordmark:before {
  content: "";
}
.devicon-drupal-plain:before {
  content: "";
}
.devicon-firefox-plain-wordmark:before {
  content: "";
}
.devicon-firefox-plain:before {
  content: "";
}
.devicon-foundation-plain-wordmark:before {
  content: "";
}
.devicon-foundation-plain:before {
  content: "";
}
.devicon-git-plain-wordmark:before {
  content: "";
}
.devicon-git-plain:before {
  content: "";
}
.devicon-grunt-line-wordmark:before {
  content: "";
}
.devicon-grunt-line:before {
  content: "";
}
.devicon-grunt-plain-wordmark:before {
  content: "";
}
.devicon-grunt-plain:before {
  content: "";
}
.devicon-gulp-plain:before {
  content: "";
}
.devicon-html5-plain-wordmark:before {
  content: "";
}
.devicon-html5-plain:before {
  content: "";
}
.devicon-ie10-original:before,
.devicon-ie10-plain:before {
  content: "";
}
.devicon-illustrator-line:before {
  content: "";
}
.devicon-illustrator-plain:before {
  content: "";
}
.devicon-inkscape-plain-wordmark:before {
  content: "";
}
.devicon-inkscape-plain:before {
  content: "";
}
.devicon-java-plain-wordmark:before {
  content: "";
}
.devicon-java-plain:before {
  content: "";
}
.devicon-javascript-plain:before {
  content: "";
}
.devicon-jquery-plain-wordmark:before {
  content: "";
}
.devicon-jquery-plain:before {
  content: "";
}
.devicon-krakenjs-plain-wordmark:before {
  content: "";
}
.devicon-krakenjs-plain:before {
  content: "";
}
.devicon-laravel-plain-wordmark:before {
  content: "";
}
.devicon-laravel-plain:before {
  content: "";
}
.devicon-less-plain-wordmark:before {
  content: "";
}
.devicon-linux-plain:before {
  content: "";
}
.devicon-mongodb-plain-wordmark:before {
  content: "";
}
.devicon-mongodb-plain:before {
  content: "";
}
.devicon-moodle-plain-wordmark:before {
  content: "";
}
.devicon-moodle-plain:before {
  content: "";
}
.devicon-mysql-plain-wordmark:before {
  content: "";
}
.devicon-mysql-plain:before {
  content: "";
}
.devicon-nodejs-plain-wordmark:before {
  content: "";
}
.devicon-nodejs-plain:before {
  content: "";
}
.devicon-oracle-original:before,
.devicon-oracle-plain:before {
  content: "";
}
.devicon-photoshop-line:before {
  content: "";
}
.devicon-photoshop-plain:before {
  content: "";
}
.devicon-php-plain:before {
  content: "";
}
.devicon-postgresql-plain-wordmark:before {
  content: "";
}
.devicon-postgresql-plain:before {
  content: "";
}
.devicon-python-plain-wordmark:before {
  content: "";
}
.devicon-python-plain:before {
  content: "";
}
.devicon-ruby-plain-wordmark:before {
  content: "";
}
.devicon-ruby-plain:before {
  content: "";
}
.devicon-sass-original:before,
.devicon-sass-plain:before {
  content: "";
}
.devicon-travis-plain-wordmark:before {
  content: "";
}
.devicon-travis-plain:before {
  content: "";
}
.devicon-trello-plain-wordmark:before {
  content: "";
}
.devicon-trello-plain:before {
  content: "";
}
.devicon-ubuntu-plain-wordmark:before {
  content: "";
}
.devicon-ubuntu-plain:before {
  content: "";
}
.devicon-vim-plain:before {
  content: "";
}
.devicon-windows8-original-wordmark:before,
.devicon-windows8-plain-wordmark:before {
  content: "";
}
.devicon-windows8-original:before,
.devicon-windows8-plain:before {
  content: "";
}
.devicon-wordpress-plain-wordmark:before {
  content: "";
}
.devicon-wordpress-plain:before {
  content: "";
}
.devicon-yii-plain-wordmark:before {
  content: "";
}
.devicon-yii-plain:before {
  content: "";
}
.devicon-zend-plain-wordmark:before {
  content: "";
}
.devicon-zend-plain:before {
  content: "";
}
.devicon-adonisjs-original.colored,
.devicon-adonisjs-original-wordmark.colored,
.devicon-adonisjs-plain.colored,
.devicon-adonisjs-plain-wordmark.colored {
  color: #5a45ff;
}
.devicon-aftereffects-plain.colored {
  color: #1f0740;
}
.devicon-amazonwebservices-original.colored,
.devicon-amazonwebservices-plain-wordmark.colored,
.devicon-amazonwebservices-plain.colored {
  color: #f7a80d;
}
.devicon-android-plain.colored,
.devicon-android-plain-wordmark.colored {
  color: #a4c439;
}
.devicon-androidstudio-plain.colored,
.devicon-androidstudio-plain-wordmark.colored {
  color: #4285f4;
}
.devicon-aarch64-plain.colored {
  color: #16358c;
}
.devicon-angularjs-plain.colored,
.devicon-angularjs-plain-wordmark.colored {
  color: #c4473a;
}
.devicon-ansible-plain.colored,
.devicon-ansible-plain-wordmark.colored {
  color: #1a1918;
}
.devicon-apache-plain.colored,
.devicon-apache-plain-wordmark.colored,
.devicon-apache-line.colored,
.devicon-apache-line-wordmark.colored {
  color: #303284;
}
.devicon-apachekafka-original.colored,
.devicon-apachekafka-original-wordmark.colored,
.devicon-apachekafka-plain.colored,
.devicon-apachekafka-plain-wordmark.colored {
  color: #231f20;
}
.devicon-appcelerator-original.colored,
.devicon-appcelerator-plain-wordmark.colored,
.devicon-appcelerator-plain.colored {
  color: #ac162c;
}
.devicon-apple-original.colored,
.devicon-apple-plain.colored {
  color: #000;
}
.devicon-appwrite-plain.colored,
.devicon-appwrite-plain-wordmark.colored,
.devicon-appwrite-original.colored,
.devicon-appwrite-original-wordmark.colored {
  color: #f02e65;
}
.devicon-arduino-plain.colored,
.devicon-arduino-plain-wordmark.colored {
  color: #00979d;
}
.devicon-atom-original.colored,
.devicon-atom-original-wordmark.colored,
.devicon-atom-plain.colored,
.devicon-atom-plain-wordmark.colored {
  color: #67595d;
}
.devicon-azure-plain.colored,
.devicon-azure-plain-wordmark.colored {
  color: #0089d6;
}
.devicon-babel-plain.colored,
.devicon-babel-original.colored {
  color: #f9dc3e;
}
.devicon-backbonejs-plain.colored,
.devicon-backbonejs-plain-wordmark.colored {
  color: #002a41;
}
.devicon-bamboo-original.colored,
.devicon-bamboo-original-wordmark.colored,
.devicon-bamboo-plain.colored,
.devicon-bamboo-plain-wordmark.colored {
  color: #1068e2;
}
.devicon-bash-plain.colored {
  color: #293138;
}
.devicon-behance-plain.colored,
.devicon-behance-plain-wordmark.colored {
  color: #0071e0;
}
.devicon-bitbucket-original.colored,
.devicon-bitbucket-original-wordmark.colored,
.devicon-bitbucket-plain.colored,
.devicon-bitbucket-plain-wordmark.colored {
  color: #205081;
}
.devicon-bootstrap-plain.colored,
.devicon-bootstrap-plain-wordmark.colored {
  color: #59407f;
}
.devicon-bulma-plain.colored {
  color: #00d1b2;
}
.devicon-bower-plain.colored,
.devicon-bower-plain-wordmark.colored,
.devicon-bower-line.colored,
.devicon-bower-line-wordmark.colored {
  color: #ef5734;
}
.devicon-c-plain.colored,
.devicon-c-plain-wordmark.colored,
.devicon-c-line.colored,
.devicon-c-line-wordmark.colored,
.devicon-c-plain-wordmark.colored,
.devicon-c-line-wordmark.colored {
  color: #03599c;
}
.devicon-cakephp-plain.colored,
.devicon-cakephp-plain-wordmark.colored {
  color: #d43d44;
}
.devicon-canva-original.colored,
.devicon-canva-plain.colored {
  color: #00c4cc;
}
.devicon-centos-plain.colored,
.devicon-centos-plain-wordmark.colored {
  color: #932178;
}
.devicon-ceylon-plain.colored {
  color: #ab710a;
}
.devicon-chrome-plain.colored,
.devicon-chrome-plain-wordmark.colored {
  color: #ce4e4e;
}
.devicon-circleci-plain.colored,
.devicon-circleci-plain-wordmark.colored {
  color: #343434;
}
.devicon-clojure-line.colored,
.devicon-clojure-plain.colored,
.devicon-clojure-plain.colored {
  color: #5881d8;
}
.devicon-cmake-plain.colored,
.devicon-cmake-plain-wordmark.colored {
  color: #0e8a16;
}
.devicon-clojurescript-plain.colored {
  color: #96ca4b;
}
.devicon-codecov-plain.colored {
  color: #e0225c;
}
.devicon-codeigniter-plain.colored,
.devicon-codeigniter-plain-wordmark.colored {
  color: #ee4323;
}
.devicon-codepen-plain.colored,
.devicon-codepen-plain-wordmark.colored,
.devicon-codepen-original.colored {
  color: #231f20;
}
.devicon-coffeescript-original.colored,
.devicon-coffeescript-original-wordmark.colored,
.devicon-coffeescript-plain.colored,
.devicon-coffeescript-plain-wordmark.colored {
  color: #28334c;
}
.devicon-composer-line.colored,
.devicon-composer-line-wordmark.colored,
.devicon-composer-plain.colored,
.devicon-composer-plain-wordmark.colored {
  color: #000;
}
.devicon-confluence-original.colored,
.devicon-confluence-original-wordmark.colored,
.devicon-confluence-plain.colored,
.devicon-confluence-plain-wordmark.colored {
  color: #205081;
}
.devicon-couchdb-plain.colored,
.devicon-couchdb-plain-wordmark.colored {
  color: #e42528;
}
.devicon-cplusplus-plain.colored,
.devicon-cplusplus-plain-wordmark.colored,
.devicon-cplusplus-line.colored,
.devicon-cplusplus-line-wordmark.colored,
.devicon-cplusplus-plain-wordmark.colored,
.devicon-cplusplus-line-wordmark.colored {
  color: #9c033a;
}
.devicon-csharp-plain.colored,
.devicon-csharp-plain-wordmark.colored,
.devicon-csharp-line.colored,
.devicon-csharp-line-wordmark.colored,
.devicon-csharp-plain-wordmark.colored,
.devicon-csharp-line-wordmark.colored {
  color: #68217a;
}
.devicon-css3-plain.colored,
.devicon-css3-plain-wordmark.colored {
  color: #3d8fc6;
}
.devicon-cucumber-plain.colored,
.devicon-cucumber-plain-wordmark.colored {
  color: #00a818;
}
.devicon-crystal-original.colored,
.devicon-crystal-original-wordmark.colored {
  color: #000;
}
.devicon-d3js-plain.colored {
  color: #f7974e;
}
.devicon-dart-plain.colored,
.devicon-dart-plain-wordmark.colored {
  color: #00a8e1;
}
.devicon-debian-plain.colored,
.devicon-debian-plain-wordmark.colored {
  color: #a80030;
}
.devicon-denojs-original.colored,
.devicon-denojs-original-wordmark.colored,
.devicon-denojs-plain.colored,
.devicon-denojs-plain-wordmark.colored {
  color: #000;
}
.devicon-devicon-plain.colored,
.devicon-devicon-plain-wordmark.colored,
.devicon-devicon-line.colored,
.devicon-devicon-line-wordmark.colored {
  color: #60be86;
}
.devicon-django-plain.colored,
.devicon-django-plain-wordmark.colored {
  color: #092e20;
}
.devicon-docker-plain.colored,
.devicon-docker-plain-wordmark.colored {
  color: #019bc6;
}
.devicon-doctrine-plain.colored,
.devicon-doctrine-plain-wordmark.colored,
.devicon-doctrine-line.colored,
.devicon-doctrine-line-wordmark.colored {
  color: #f56d39;
}
.devicon-dot-net-plain.colored,
.devicon-dot-net-plain-wordmark.colored {
  color: #1384c8;
}
.devicon-dotnetcore-plain.colored {
  color: #623697;
}
.devicon-drupal-plain.colored,
.devicon-drupal-plain-wordmark.colored {
  color: #0073ba;
}
.devicon-digitalocean-plain.colored,
.devicon-digitalocean-plain-wordmark.colored {
  color: #0080ff;
}
.devicon-discordjs-plain.colored,
.devicon-discordjs-plain-wordmark.colored {
  color: #2a2c3e;
}
.devicon-electron-original.colored,
.devicon-electron-original-wordmark.colored {
  color: #47848f;
}
.devicon-eleventy-plain.colored {
  color: #1f1f1f;
}
.devicon-elixir-plain.colored,
.devicon-elixir-plain-wordmark.colored {
  color: #380a4d;
}
.devicon-elm-plain.colored,
.devicon-elm-plain-wordmark.colored {
  color: #34495e;
}
.devicon-ember-original-wordmark.colored,
.devicon-ember-plain-wordmark.colored {
  color: #dd3f24;
}
.devicon-embeddedc-plain.colored,
.devicon-embeddedc-plain-wordmark.colored {
  color: #444;
}
.devicon-erlang-plain.colored,
.devicon-erlang-plain-wordmark.colored {
  color: #a90533;
}
.devicon-eslint-original.colored,
.devicon-eslint-original-wordmark.colored,
.devicon-eslint-plain.colored,
.devicon-eslint-plain-wordmark.colored {
  color: #4b32c3;
}
.devicon-express-original.colored,
.devicon-express-original-wordmark.colored {
  color: #444;
}
.devicon-facebook-plain.colored,
.devicon-facebook-original.colored {
  color: #3d5a98;
}
.devicon-feathersjs-original.colored {
  color: #333;
}
.devicon-figma-plain.colored {
  color: #f24e1e;
}
.devicon-filezilla-plain.colored,
.devicon-filezilla-plain-wordmark.colored {
  color: #b30000;
}
.devicon-firebase-plain.colored,
.devicon-firebase-plain-wordmark.colored {
  color: #f58220;
}
.devicon-firefox-plain.colored,
.devicon-firefox-plain-wordmark.colored {
  color: #dd732a;
}
.devicon-flask-original.colored,
.devicon-flask-original-wordmark.colored,
.devicon-flask-plain.colored,
.devicon-flask-plain-wordmark.colored {
  color: #010101;
}
.devicon-flutter-plain.colored {
  color: #3fb6d3;
}
.devicon-foundation-plain.colored,
.devicon-foundation-plain-wordmark.colored {
  color: #008cba;
}
.devicon-fsharp-plain.colored {
  color: #378bba;
}
.devicon-gatling-plain.colored,
.devicon-gatling-plain-wordmark.colored {
  color: #e77500;
}
.devicon-gatsby-plain.colored,
.devicon-gatsby-plain-wordmark.colored {
  color: #64328b;
}
.devicon-rect-plain.colored {
  color: #262626;
}
.devicon-gcc-plain.colored {
  color: #ffcfab;
}
.devicon-gentoo-plain-wordmark.colored,
.devicon-gentoo-plain.colored {
  color: #9991d9;
}
.devicon-gimp-plain.colored {
  color: #716955;
}
.devicon-git-plain.colored,
.devicon-git-plain-wordmark.colored {
  color: #f34f29;
}
.devicon-github-original.colored,
.devicon-github-original-wordmark.colored,
.devicon-github-plain.colored {
  color: #181616;
}
.devicon-gitlab-plain.colored,
.devicon-gitlab-plain-wordmark.colored {
  color: #e24329;
}
.devicon-gitter-plain.colored,
.devicon-gitter-plain-wordmark.colored {
  color: #000;
}
.devicon-go-original-wordmark.colored,
.devicon-go-plain.colored,
.devicon-go-line.colored,
.devicon-go-plain-wordmark.colored {
  color: #00acd7;
}
.devicon-google-plain.colored,
.devicon-google-plain-wordmark.colored,
.devicon-google-original.colored,
.devicon-google-original-wordmark.colored {
  color: #587dbd;
}
.devicon-googlecloud-plain.colored,
.devicon-googlecloud-plain-wordmark.colored {
  color: #557ebf;
}
.devicon-gradle-plain.colored,
.devicon-gradle-plain-wordmark.colored {
  color: #02303a;
}
.devicon-grafana-original.colored,
.devicon-grafana-original-wordmark.colored,
.devicon-grafana-plain.colored,
.devicon-grafana-plain-wordmark.colored {
  color: #e78040;
}
.devicon-grails-plain.colored {
  color: #feb672;
}
.devicon-graphql-plain.colored,
.devicon-graphql-plain-wordmark.colored {
  color: #e434aa;
}
.devicon-groovy-plain.colored {
  color: #619cbc;
}
.devicon-grunt-plain.colored,
.devicon-grunt-plain-wordmark.colored,
.devicon-grunt-line.colored,
.devicon-grunt-line-wordmark.colored {
  color: #fcaa1a;
}
.devicon-gulp-plain.colored {
  color: #eb4a4b;
}
.devicon-godot-plain.colored,
.devicon-godot-plain-wordmark.colored {
  color: #478cbf;
}
.devicon-haskell-plain.colored,
.devicon-haskell-plain-wordmark.colored {
  color: #5e5185;
}
.devicon-handlebars-plain.colored,
.devicon-handlebars-plain-wordmark.colored {
  color: #000;
}
.devicon-haxe-plain.colored {
  color: #ea8220;
}
.devicon-heroku-original.colored,
.devicon-heroku-original-wordmark.colored,
.devicon-heroku-plain.colored,
.devicon-heroku-plain-wordmark.colored,
.devicon-heroku-line.colored,
.devicon-heroku-line-wordmark.colored,
.devicon-heroku-original.colored,
.devicon-heroku-original-wordmark.colored {
  color: #6762a6;
}
.devicon-html5-plain.colored,
.devicon-html5-plain-wordmark.colored {
  color: #e54d26;
}
.devicon-hugo-plain.colored,
.devicon-hugo-plain-wordmark.colored {
  color: #ff4088;
}
.devicon-ie10-original.colored,
.devicon-ie10-plain.colored {
  color: #1ebbee;
}
.devicon-ifttt-original.colored,
.devicon-ifttt-plain.colored {
  color: #000;
}
.devicon-illustrator-plain.colored,
.devicon-illustrator-line.colored {
  color: #faa625;
}
.devicon-inkscape-plain.colored,
.devicon-inkscape-plain-wordmark.colored {
  color: #000;
}
.devicon-intellij-plain.colored,
.devicon-intellij-plain-wordmark.colored {
  color: #136ba2;
}
.devicon-ionic-original.colored,
.devicon-ionic-original-wordmark.colored {
  color: #4e8ef7;
}
.devicon-jamstack-original.colored,
.devicon-jamstack-plain-wordmark.colored,
.devicon-jamstack-plain.colored {
  color: #f0047f;
}
.devicon-jasmine-plain.colored,
.devicon-jasmine-plain-wordmark.colored {
  color: #8a4182;
}
.devicon-java-plain.colored,
.devicon-java-plain-wordmark.colored {
  color: #ea2d2e;
}
.devicon-javascript-plain.colored {
  color: #f0db4f;
}
.devicon-jeet-plain.colored,
.devicon-jeet-plain-wordmark.colored {
  color: #ff664a;
}
.devicon-jest-plain.colored {
  color: #99425b;
}
.devicon-jenkins-line.colored,
.devicon-jenkins-plain.colored {
  color: #f0d6b7;
}
.devicon-jetbrains-plain.colored {
  color: #fdcc21;
}
.devicon-jira-plain.colored,
.devicon-jira-plain-wordmark.colored {
  color: #2684ff;
}
.devicon-jquery-plain.colored,
.devicon-jquery-plain-wordmark.colored {
  color: #0769ad;
}
.devicon-julia-plain.colored,
.devicon-julia-plain-wordmark.colored {
  color: #28a745;
}
.devicon-jupyter-plain.colored,
.devicon-jupyter-plain-wordmark.colored {
  color: #f37726;
}
.devicon-kaggle-original.colored,
.devicon-kaggle-original-wordmark.colored,
.devicon-kaggle-plain.colored,
.devicon-kaggle-plain-wordmark.colored {
  color: #20beff;
}
.devicon-karma-plain.colored {
  color: #56c5a8;
}
.devicon-kotlin-plain.colored,
.devicon-kotlin-plain-wordmark.colored {
  color: #7c6db2;
}
.devicon-knockout-plain-wordmark.colored {
  color: #e42e16;
}
.devicon-krakenjs-plain.colored,
.devicon-krakenjs-plain-wordmark.colored {
  color: #0081c2;
}
.devicon-kubernetes-plain.colored,
.devicon-kubernetes-plain-wordmark.colored {
  color: #486bb3;
}
.devicon-labview-plain.colored,
.devicon-labview-plain-wordmark.colored {
  color: #fed500;
}
.devicon-laravel-plain.colored,
.devicon-laravel-plain-wordmark.colored {
  color: #fd4f31;
}
.devicon-latex-original.colored,
.devicon-latex-plain.colored {
  color: #000;
}
.devicon-less-plain-wordmark.colored {
  color: #2a4d80;
}
.devicon-linkedin-plain.colored,
.devicon-linkedin-plain-wordmark.colored {
  color: #0076b2;
}
.devicon-lua-plain.colored,
.devicon-lua-plain-wordmark.colored {
  color: navy;
}
.devicon-linux-plain.colored {
  color: #000;
}
.devicon-materialui-plain.colored {
  color: #1fa6ca;
}
.devicon-matlab-plain.colored,
.devicon-matlab-line.colored {
  color: #6dd0c7;
}
.devicon-magento-original.colored,
.devicon-magento-original-wordmark.colored,
.devicon-magento-line.colored {
  color: #f26322;
}
.devicon-markdown-original.colored,
.devicon-markdown-plain.colored {
  color: #000;
}
.devicon-maya-plain.colored,
.devicon-maya-plain-wordmark.colored {
  color: #149b9a;
}
.devicon-meteor-plain.colored,
.devicon-meteor-plain-wordmark.colored {
  color: #df5052;
}
.devicon-minitab-plain.colored {
  color: #8dc63f;
}
.devicon-mocha-plain.colored {
  color: #8d6748;
}
.devicon-modx-plain.colored,
.devicon-modx-plain-wordmark.colored {
  color: #00decc;
}
.devicon-mongodb-plain.colored,
.devicon-mongodb-plain-wordmark.colored {
  color: #4faa41;
}
.devicon-moodle-plain.colored,
.devicon-moodle-plain-wordmark.colored {
  color: #f7931e;
}
.devicon-msdos-line.colored {
  color: red;
}
.devicon-mysql-plain.colored,
.devicon-mysql-plain-wordmark.colored {
  color: #00618a;
}
.devicon-neo4j-plain.colored,
.devicon-neo4j-plain-wordmark.colored {
  color: #018bff;
}
.devicon-nestjs-plain.colored,
.devicon-nestjs-plain-wordmark.colored {
  color: #df234f;
}
.devicon-networkx-original.colored {
  color: #2c7fb8;
}
.devicon-nextjs-original.colored,
.devicon-nextjs-plain.colored,
.devicon-nextjs-line.colored,
.devicon-nextjs-original-wordmark.colored,
.devicon-nextjs-plain-wordmark.colored,
.devicon-nextjs-plain.colored,
.devicon-nextjs-plain-wordmark.colored {
  color: #000;
}
.devicon-nginx-original.colored,
.devicon-nginx-original-wordmark.colored,
.devicon-nginx-plain.colored,
.devicon-nginx-plain-wordmark.colored,
.devicon-nginx-original-wordmark.colored,
.devicon-nginx-plain.colored,
.devicon-nginx-plain-wordmark.colored {
  color: #090;
}
.devicon-nixos-plain.colored,
.devicon-nixos-plain-wordmark.colored {
  color: #5277c3;
}
.devicon-nodejs-plain.colored,
.devicon-nodejs-plain-wordmark.colored {
  color: #83cd29;
}
.devicon-nodewebkit-plain.colored,
.devicon-nodewebkit-plain-wordmark.colored,
.devicon-nodewebkit-line.colored,
.devicon-nodewebkit-line-wordmark.colored {
  color: #3d3b47;
}
.devicon-npm-original-wordmark.colored {
  color: #cb3837;
}
.devicon-nuget-original.colored,
.devicon-nuget-original-wordmark.colored {
  color: #004880;
}
.devicon-numpy-original.colored,
.devicon-numpy-original-wordmark.colored,
.devicon-numpy-plain.colored {
  color: #4dabcf;
}
.devicon-nuxtjs-plain.colored,
.devicon-nuxtjs-plain-wordmark.colored {
  color: #00c48d;
}
.devicon-objectivec-plain.colored {
  color: #0b5a9d;
}
.devicon-opera-plain.colored,
.devicon-opera-plain-wordmark.colored {
  color: #f7192d;
}
.devicon-ocaml-plain.colored,
.devicon-ocaml-plain-wordmark.colored {
  color: #f18803;
}
.devicon-openal-plain.colored {
  color: #7e000d;
}
.devicon-opengl-plain.colored {
  color: #5586a4;
}
.devicon-opensuse-plain.colored,
.devicon-opensuse-plain-wordmark.colored {
  color: #73ba25;
}
.devicon-oracle-original.colored,
.devicon-oracle-plain.colored {
  color: #ea1b22;
}
.devicon-pandas-original.colored,
.devicon-pandas-original-wordmark.colored,
.devicon-pandas-plain.colored,
.devicon-pandas-plain-wordmark.colored {
  color: #130754;
}
.devicon-perl-plain.colored {
  color: #212177;
}
.devicon-phalcon-plain.colored {
  color: #76c39b;
}
.devicon-photoshop-plain.colored,
.devicon-photoshop-line.colored {
  color: #80b5e2;
}
.devicon-php-plain.colored {
  color: #6181b6;
}
.devicon-phpstorm-plain.colored,
.devicon-phpstorm-plain-wordmark.colored {
  color: #5058a6;
}
.devicon-podman-plain.colored,
.devicon-podman-plain-wordmark.colored {
  color: #872b9e;
}
.devicon-polygon-plain.colored {
  color: #38285b;
}
.devicon-postgresql-plain.colored,
.devicon-postgresql-plain-wordmark.colored {
  color: #336791;
}
.devicon-premierepro-plain.colored {
  color: #2a0634;
}
.devicon-processing-plain.colored,
.devicon-processing-plain-wordmark.colored {
  color: #000;
}
.devicon-protractor-plain.colored,
.devicon-protractor-plain-wordmark.colored {
  color: #b7111d;
}
.devicon-putty-plain.colored {
  color: #0000fc;
}
.devicon-pycharm-plain.colored,
.devicon-pycharm-plain-wordmark.colored {
  color: #4d8548;
}
.devicon-python-plain.colored,
.devicon-python-plain-wordmark.colored {
  color: #ffd845;
}
.devicon-pytorch-original.colored,
.devicon-pytorch-plain-wordmark.colored,
.devicon-pytorch-plain.colored {
  color: #ee4c2c;
}
.devicon-raspberrypi-line.colored,
.devicon-raspberrypi-line-wordmark.colored {
  color: #c51850;
}
.devicon-phoenix-plain.colored,
.devicon-phoenix-plain-wordmark.colored {
  color: #f15524;
}
.devicon-qt-original.colored,
.devicon-qt-plain.colored {
  color: #41cd52;
}
.devicon-r-original.colored,
.devicon-r-plain.colored {
  color: #2369bc;
}
.devicon-rails-plain.colored,
.devicon-rails-plain-wordmark.colored {
  color: #c00;
}
.devicon-react-original.colored,
.devicon-react-original-wordmark.colored,
.devicon-react-plain.colored,
.devicon-react-plain-wordmark.colored {
  color: #61dafb;
}
.devicon-redhat-plain.colored,
.devicon-redhat-plain-wordmark.colored {
  color: #e93442;
}
.devicon-redis-plain.colored,
.devicon-redis-plain-wordmark.colored {
  color: #d82c20;
}
.devicon-redux-original.colored,
.devicon-redux-plain.colored {
  color: #764abc;
}
.devicon-rocksdb-plain.colored {
  color: #f5be17;
}
.devicon-ruby-plain.colored,
.devicon-ruby-plain-wordmark.colored {
  color: #d91404;
}
.devicon-rubymine-plain.colored,
.devicon-rubymine-plain-wordmark.colored {
  color: #c12c4c;
}
.devicon-rust-plain.colored {
  color: #000;
}
.devicon-safari-plain.colored,
.devicon-safari-plain-wordmark.colored,
.devicon-safari-line-wordmark.colored,
.devicon-safari-line.colored {
  color: #1b88ca;
}
.devicon-salesforce-plain.colored {
  color: #00a1e0;
}
.devicon-sdl-plain.colored {
  color: #173354;
}
.devicon-rstudio-plain.colored {
  color: #75aadb;
}
.devicon-sass-original.colored,
.devicon-sass-plain.colored {
  color: #c69;
}
.devicon-scala-plain.colored,
.devicon-scala-plain-wordmark.colored {
  color: #de3423;
}
.devicon-selenium-original.colored,
.devicon-selenium-plain.colored {
  color: #cf0a2c;
}
.devicon-sequelize-plain.colored,
.devicon-sequelize-plain-wordmark.colored,
.devicon-sequelize-plain.colored,
.devicon-sequelize-plain-wordmark.colored {
  color: #3b4b72;
}
.devicon-shopware-original.colored,
.devicon-shopware-original-wordmark.colored {
  color: #179eff;
}
.devicon-shotgrid-plain.colored,
.devicon-shotgrid-original-wordmark.colored,
.devicon-shotgrid-plain-wordmark.colored {
  color: #000;
}
.devicon-sketch-line.colored,
.devicon-sketch-line-wordmark.colored {
  color: #fdad00;
}
.devicon-slack-plain.colored,
.devicon-slack-plain-wordmark.colored {
  color: #2d333a;
}
.devicon-socketio-original.colored,
.devicon-socketio-original-wordmark.colored,
.devicon-socketio-plain.colored,
.devicon-socketio-plain-wordmark.colored {
  color: #010101;
}
.devicon-solidity-plain.colored {
  color: #383838;
}
.devicon-sourcetree-original.colored,
.devicon-sourcetree-original-wordmark.colored,
.devicon-sourcetree-plain.colored,
.devicon-sourcetree-plain-wordmark.colored {
  color: #205081;
}
.devicon-spring-plain.colored,
.devicon-spring-plain-wordmark.colored {
  color: #5fb832;
}
.devicon-spss-plain.colored {
  color: #cc1e4c;
}
.devicon-sqlalchemy-plain.colored,
.devicon-sqlalchemy-original-wordmark.colored,
.devicon-sqlalchemy-plain-wordmark.colored {
  color: #333;
}
.devicon-sqlite-plain.colored,
.devicon-sqlite-plain-wordmark.colored {
  color: #0f80cc;
}
.devicon-subversion-original.colored,
.devicon-subversion-plain.colored {
  color: #809cc8;
}
.devicon-microsoftsqlserver-plain.colored,
.devicon-microsoftsqlserver-plain-wordmark.colored {
  color: #909daa;
}
.devicon-ssh-original.colored,
.devicon-ssh-original-wordmark.colored,
.devicon-ssh-plain.colored,
.devicon-ssh-plain-wordmark.colored {
  color: #231f20;
}
.devicon-stylus-original.colored {
  color: #333;
}
.devicon-svelte-plain.colored,
.devicon-svelte-plain-wordmark.colored {
  color: #ff3e00;
}
.devicon-swift-plain.colored,
.devicon-swift-plain-wordmark.colored {
  color: #f05138;
}
.devicon-symfony-original.colored,
.devicon-symfony-original-wordmark.colored,
.devicon-symfony-plain.colored,
.devicon-symfony-plain-wordmark.colored {
  color: #1a171b;
}
.devicon-storybook-plain.colored,
.devicon-storybook-plain-wordmark.colored {
  color: #ff4785;
}
.devicon-tailwindcss-original-wordmark.colored,
.devicon-tailwindcss-plain.colored {
  color: #2298bd;
}
.devicon-tensorflow-original.colored,
.devicon-tensorflow-original-wordmark.colored,
.devicon-tensorflow-line.colored,
.devicon-tensorflow-line-wordmark.colored {
  color: #ff6f00;
}
.devicon-terraform-plain.colored,
.devicon-terraform-plain-wordmark.colored {
  color: #5c4ee5;
}
.devicon-threejs-original.colored,
.devicon-threejs-original-wordmark.colored {
  color: #000;
}
.devicon-tomcat-line.colored,
.devicon-tomcat-line-wordmark.colored {
  color: #d1a41a;
}
.devicon-tortoisegit-plain.colored,
.devicon-tortoisegit-line.colored {
  color: #4a8fb5;
}
.devicon-towergit-plain.colored,
.devicon-towergit-plain-wordmark.colored {
  color: #d18900;
}
.devicon-travis-plain.colored,
.devicon-travis-plain-wordmark.colored {
  color: #bb2031;
}
.devicon-thealgorithms-plain.colored,
.devicon-thealgorithms-plain-wordmark.colored {
  color: #00bcb4;
}
.devicon-trello-plain.colored,
.devicon-trello-plain-wordmark.colored {
  color: #23719f;
}
.devicon-twitter-original.colored,
.devicon-twitter-plain.colored {
  color: #1da1f2;
}
.devicon-typescript-plain.colored,
.devicon-typescript-original.colored {
  color: #007acc;
}
.devicon-typo3-plain.colored,
.devicon-typo3-plain-wordmark.colored {
  color: #f49700;
}
.devicon-ubuntu-plain.colored,
.devicon-ubuntu-plain-wordmark.colored {
  color: #dd4814;
}
.devicon-unity-original.colored,
.devicon-unity-original-wordmark.colored {
  color: #000;
}
.devicon-unix-original.colored {
  color: #4051b5;
}
.devicon-unrealengine-original.colored,
.devicon-unrealengine-original-wordmark.colored,
.devicon-unrealengine-plain.colored,
.devicon-unrealengine-plain-wordmark.colored {
  color: #000;
}
.devicon-uwsgi-plain.colored {
  color: #bad05e;
}
.devicon-vagrant-plain.colored,
.devicon-vagrant-plain-wordmark.colored {
  color: #127eff;
}
.devicon-vim-plain.colored {
  color: #179a33;
}
.devicon-visualstudio-plain.colored,
.devicon-visualstudio-plain-wordmark.colored {
  color: #68217a;
}
.devicon-vuejs-plain.colored,
.devicon-vuejs-plain-wordmark.colored,
.devicon-vuejs-line.colored,
.devicon-vuejs-line-wordmark.colored {
  color: #41b883;
}
.devicon-vuestorefront-plain.colored {
  color: #5ecf7b;
}
.devicon-vscode-plain.colored,
.devicon-vscode-plain-wordmark.colored {
  color: #3c99d4;
}
.devicon-webflow-original.colored {
  color: #4353ff;
}
.devicon-weblate-plain.colored,
.devicon-weblate-plain-wordmark.colored {
  color: #2eccaa;
}
.devicon-webpack-plain.colored,
.devicon-webpack-plain-wordmark.colored {
  color: #1c78c0;
}
.devicon-webstorm-plain.colored,
.devicon-webstorm-plain-wordmark.colored {
  color: #2788b5;
}
.devicon-windows8-original.colored,
.devicon-windows8-original-wordmark.colored,
.devicon-windows8-plain.colored,
.devicon-windows8-plain-wordmark.colored {
  color: #00adef;
}
.devicon-woocommerce-plain.colored,
.devicon-woocommerce-plain-wordmark.colored {
  color: #7f54b3;
}
.devicon-wordpress-plain.colored,
.devicon-wordpress-plain-wordmark.colored {
  color: #494949;
}
.devicon-xamarin-original.colored,
.devicon-xamarin-original-wordmark.colored,
.devicon-xamarin-plain.colored,
.devicon-xamarin-plain-wordmark.colored {
  color: #3498db;
}
.devicon-xcode-plain.colored {
  color: #069cec;
}
.devicon-xd-plain.colored,
.devicon-xd-line.colored {
  color: #dd80bc;
}
.devicon-yarn-plain.colored,
.devicon-yarn-plain-wordmark.colored {
  color: #2c8ebb;
}
.devicon-yii-plain.colored,
.devicon-yii-plain-wordmark.colored {
  color: #0073bb;
}
.devicon-yunohost-plain.colored {
  color: #fff;
}
.devicon-zend-plain.colored,
.devicon-zend-plain-wordmark.colored {
  color: #68b604;
}
.devicon-zig-original.colored,
.devicon-zig-plain-wordmark.colored,
.devicon-zig-plain.colored {
  color: #f7a41d;
}
.devicon-pytest-plain.colored,
.devicon-pytest-plain-wordmark.colored {
  color: #009fe3;
}
.devicon-opencv-plain.colored,
.devicon-opencv-plain-wordmark.colored {
  color: #128dff;
}
.devicon-fastapi-plain.colored,
.devicon-fastapi-plain-wordmark.colored {
  color: #009688;
}
.devicon-k3s-original.colored,
.devicon-k3s-plain-wordmark.colored,
.devicon-k3s-plain.colored {
  color: #ffc519;
}
.devicon-packer-original.colored,
.devicon-packer-original-wordmark.colored,
.devicon-packer-plain.colored,
.devicon-packer-plain-wordmark.colored {
  color: #1d94dd;
}
.devicon-anaconda-original.colored,
.devicon-anaconda-original-wordmark.colored,
.devicon-anaconda-plain.colored,
.devicon-anaconda-plain-wordmark.colored {
  color: #3eb049;
}
.devicon-rspec-original.colored,
.devicon-rspec-original-wordmark.colored,
.devicon-rspec-plain.colored,
.devicon-rspec-plain-wordmark.colored {
  color: #6de1fa;
}
.devicon-argocd-plain.colored,
.devicon-argocd-plain-wordmark.colored {
  color: #ef7b4d;
}
.devicon-prometheus-original.colored,
.devicon-prometheus-original-wordmark.colored {
  color: #e75225;
}
.devicon-blender-original.colored,
.devicon-blender-original-wordmark.colored {
  color: #dc7b2e;
}
.devicon-dropwizard-plain.colored {
  color: #24265d;
}
.devicon-vuetify-line.colored,
.devicon-vuetify-plain.colored {
  color: #1697f6;
}
.devicon-fedora-plain.colored {
  color: #294172;
}
