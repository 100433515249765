.now-text {
  margin-left: 20%;
  margin-right: 20%;
  font-size: 20px;
}

.logo {
  padding-top: 5px;
  transition: filter 1s;
}

.logo:hover {
  filter: drop-shadow(0px 0px 15px #ffffff);
  transition: filter 1s;
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .now-text {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 767px) {
  .now-text {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.learning-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 1rem;
  margin: 2em 2em;
  justify-content: center;
  justify-items: center;
}

.hobbies-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 4rem;
  margin: 2em 2em;
  justify-content: center;
  justify-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .hobbies-grid,
  .learning-grid {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 1099px) {
  .learning-grid,
  .hobbies-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
}
