.error-page {
  font-family: ChunkFive;
  font-weight: normal;
  margin-left: 15%;
  margin-right: 15%;
}

.error-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.error-title {
  font-weight: normal;
  font-size: 70px;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.error-text {
  font-size: 30px;
  margin-right: 15%;
  grid-area: 1 / 2 / 2 / 3;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.error-image {
  grid-area: 1 / 1 / 2 / 2;
}

.frustration {
  padding: 10px;
  height: 400px;
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .error-page {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .error-grid {
    display: block;
  }
  .frustration {
    height: 300px;
  }
  .error-title {
    font-size: 60px;
  }
  .error-text {
    font-size: 25px;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (max-width: 767px) {
  .error-grid {
    display: block;
  }
  .error-title {
    font-size: 40px;
  }
  .frustration {
    height: 200px;
  }
  .error-text {
    font-size: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .error-page {
    margin-left: 5%;
    margin-right: 5%;
  }
}
