::selection {
  background: #fff;
  color: #000;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #fff;
}

::-webkit-scrollbar-thumb:active {
  background: rgb(0, 128, 255);
}

.app {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

.title,
.tagline {
  font-family: "ChunkFive";
  font-size: 40px;
  font-weight: normal;
}

.subheader {
  font-family: "ChunkFive";
  font-size: 30px;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
}

p {
  line-height: 1.5;
  margin-block-start: 10px;
  margin-block-end: 10px;
  font-family: "Montserrat";
}

h1 {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.title {
  margin-block-start: 5px;
  margin-block-end: 5px;
}

.links a {
  text-decoration: none;
}

.link {
  margin-block-start: 10px;
  margin-block-end: 10px;
}

/* Fonts */

@font-face {
  font-family: "ChunkFive";
  src: url(./fonts/ChunkFive-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Phosphate";
  src: url(./fonts/Phosphate-Inline.otf) format("opentype");
}

@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-Medium.otf) format("opentype");
}

html {
  min-height: 100%;
}

body {
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1),
    rgb(0, 44, 83),
    rgb(0, 89, 166),
    rgb(0, 128, 255)
  );
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.big-blue-button {
  background-color: #138dff;
  font-family: "ChunkFive";
  border: none;
  color: white;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 40px;
  margin: 10px;
  width: 250px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: normal;
  transition: background-color 1s;
}

.big-blue-button:hover {
  background-color: #e7e7e7;
  color: black;
  transition: background-color 1s;
}

.link {
  font-family: ChunkFive;
  font-size: 30px;
  font-weight: normal;
  padding-bottom: 10px;
}
a {
  text-decoration: none;
  color: rgb(126, 210, 255);
}

.text-link:hover {
  color: white;
  text-decoration: underline;
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .big-blue-button {
    font-size: 30px;
    width: 250px;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 30px;
    margin-block-start: 20px;
    margin-block-end: 20px;
  }
  body {
    background-attachment: scroll;
  }
  .big-blue-button {
    font-size: 25px;
    width: 150px;
    padding: 12px 12px;
    margin: 7px;
  }
}
