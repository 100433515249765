.about-header,
.about-text {
  margin-left: 20%;
  margin-right: 20%;
}

.headshot {
  border-radius: 100%;
  height: 250px;
  width: 250px;
  padding: 10px;
}

.about-text {
  font-size: 20px;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 2em 2em;
  justify-content: center;
  justify-items: center;
}

.devicon {
  font-size: 125px;
}

.react-icon:hover,
.wordpress-icon:hover,
.vscode-icon:hover,
.trello-icon:hover {
  color: #54d1fa;
  transition: color 0.5s;
  text-shadow: 2px 2px #000;
}

.javascript-icon:hover {
  color: #f2ff01;
  transition: color 0.5s;
  text-shadow: 2px 2px #000;
}

.html5-icon:hover,
.git-icon:hover,
.npm-icon:hover {
  color: #f26d00;
  transition: color 0.5s;
  text-shadow: 2px 2px #000;
}

.nodejs-icon:hover {
  color: #09ff00;
  transition: color 0.5s;
  text-shadow: 2px 2px #000;
}

.express-icon:hover,
.markdown-icon:hover {
  color: #969595;
  transition: color 0.5s;
  text-shadow: 2px 2px #000;
}

.postgresql-icon:hover {
  color: #107ab3;
  transition: color 0.5s;
  text-shadow: 2px 2px #000;
}

.photoshop-icon:hover,
.docker-icon:hover,
.css3-icon:hover {
  color: #2890f4;
  transition: color 0.5s;
  text-shadow: 2px 2px #000;
}

.figma-icon:hover {
  color: #7f00fd;
  transition: color 0.5s;
  text-shadow: 2px 2px #000;
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .about-text {
    margin-left: 10%;
    margin-right: 10%;
  }
  .skills-grid {
    padding-left: 10%;
    padding-right: 10%;
  }
  .devicon {
    font-size: 80px;
  }
}

@media (max-width: 767px) {
  .wordmark {
    height: 60px;
  }
  .headshot {
    height: 150px;
    width: 150px;
  }
  .about-header,
  .about-text,
  .bio-text {
    margin-left: 5%;
    margin-right: 5%;
  }
  .about-kicker {
    font-size: 35px;
  }
  .about-header {
    font-size: 28px;
  }
  .skills-grid {
    padding-left: 5%;
    padding-right: 5%;
  }
  .devicon {
    font-size: 60px;
  }
}
