@media (max-width: 767px) {
  .tagline {
    font-size: 25px;
  }
  .tagline-box {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.tagline-box {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
}

.tagline {
  line-height: 1.2;
  position: absolute;
  opacity: 0;
  animation: fadeInOut 15s linear infinite;
}

.tagline:nth-child(1) {
  animation-delay: 0s;
}

.tagline:nth-child(2) {
  animation-delay: 3s;
}

.tagline:nth-child(3) {
  animation-delay: 6s;
}

.tagline:nth-child(4) {
  animation-delay: 9s;
}

.tagline:nth-child(5) {
  animation-delay: 12s;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  3%,
  22% {
    opacity: 1;
  }
  25%,
  97% {
    opacity: 0;
  }
}
