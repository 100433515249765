.name {
  font-family: "ChunkFive";
  font-size: 100px;
  font-weight: normal;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

a .name {
  color: white;
}

.name:hover {
  text-decoration: underline;
  text-decoration-thickness: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .name {
    font-size: 75px;
  }
}

@media (max-width: 767px) {
  .name {
    font-size: 60px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
