.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin: 2em 2em;
  justify-content: center;
  justify-items: center;
}

.project-button {
  background-color: #138dff;
  font-family: "ChunkFive";
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
  margin: 5px;
  width: 200px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: normal;
  transition: background-color 1s;
}

.project-button:hover {
  background-color: #e7e7e7;
  color: black;
  transition: background-color 1s;
}

.portfolio-description {
  font-size: 20px;
  padding-left: 5%;
  padding-right: 5%;
}

.project-image img {
  transition: 0.5s;
  border: 10px solid rgba(0, 0, 0, 0);
}

.worthashot-image img:hover {
  border: 10px solid #fdc500;
}

.saucesource-image img:hover {
  border: 10px solid #ff7800;
}

.resultsfixer-image img:hover {
  border: 10px solid #8f61d7;
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .portfolio-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .project-button {
    font-size: 25px;
    width: 200px;
  }
  .portfolio-description {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (max-width: 767px) {
  .portfolio-grid {
    grid-template-columns: repeat(1, 1fr);
    margin: 0em 0em;
  }
  .thumbnail {
    width: 350px;
  }
  .project-button {
    font-size: 20px;
    width: 150px;
    padding: 10px 10px;
  }
  .portfolio-description {
    font-size: 18px;
  }

  .worthashot-image img {
    border: 4px solid #fdc500;
  }

  .worthashot-image img:hover {
    border: 4px solid #fdc500;
  }

  .saucesource-image img {
    border: 4px solid #ff7800;
  }

  .saucesource-image img:hover {
    border: 4px solid #ff7800;
  }

  .resultsfixer-image img {
    border: 4px solid #8f61d7;
  }

  .resultsfixer-image img:hover {
    border: 4px solid #8f61d7;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px) {
  .thumbnail {
    width: 500px;
  }
  .portfolio-grid {
    margin: 0em 0em;
  }
}
