.footer-text {
  font-family: "Montserrat";
  font-weight: normal;
  font-size: 20px;
  margin: 10px;
}

.icon {
  padding: 10px;
  transition: filter 1s;
}

.icon:hover {
  filter: drop-shadow(0px 0px 15px #ffffff);
  transition: filter 1s;
}

.pi {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: bold;
  font-size: 18px;
}

.pi a {
  color: #fff;
}

.pi a:hover {
  color: #aaa;
}

@media (max-width: 767px) {
  .footer-text {
    font-size: 15px;
  }
  .pi {
    font-size: 14px;
  }
}
